import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import { Text, Select, Checkbox, TextArea } from 'informed'
import Quill from '../../Quill';

import CssClassNames from '../../../scss/CssClassNames';
import moduledStyles from './styles.module.scss';
const { className } = new CssClassNames(moduledStyles, ['utility', 'transition']);

export default class extends PureComponent {

    static propTypes = {
        field: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            label: PropTypes.string
        })),
    }

    render() {
        const {
            type = "text",
            label,
            field,
            error = null,
            errorText = '',
            errorClassName = null,
            placeholder = '',
            autoComplete = '',
            min = '',
            max = '',
            maxLength = '',
            rows = '',
            defaultValue,
            disabled = null,
            options = null,
            required = false,
            onFocus = null
        } = this.props;

        const addInput = (inputType) => {
            switch (inputType) {
                case 'text': 
                case 'color':
                case 'number':
                case 'integer':
                    return (
                        <Text id={field} field={field} required={required}
                            {...{ type, placeholder, autoComplete, disabled }}
                            {...( min ? {min: min} : {})}
                            {...( max ? {max: max} : {})}
                            {...( onFocus ? {onFocus} : {})}
                            {...className(`form-control ${inputType === 'number' ? 'px-1':''} ${error ? 'is-invalid' : ''} ${this.props.className ? this.props.className : ''}`)}
                        />
                    );
                case 'checkbox':
                    return (
                        <Checkbox id={field} field={field} disabled={disabled} required={required}
                            {...className(`form-check-input ml-2 ${error ? 'is-invalid' : ''} ${this.props.className ? this.props.className : ''}`)}
                        />
                    );
                case 'textArea':
                    return (
                        <TextArea id={field} field={field} required={required}
                            {...{ placeholder, autoComplete, disabled }}
                            {...( maxLength ? {maxLength: maxLength} : {})}
                            {...( rows ? {rows: rows} : {})}
                            {...( onFocus ? {onFocus} : {})}
                            {...className(`form-control ${error ? 'is-invalid' : ''} ${this.props.className ? this.props.className : ''}`)}
                        />
                    );
                case 'html':
                    return (
                        <Quill id={field} field={field} placeholder={placeholder} defaultValue={defaultValue} />
                    )
                default:
                    return (
                        <Text id={field} field={field} required={required}
                            {...{ type, placeholder, autoComplete, disabled }}
                            {...( onFocus ? {onFocus} : {})}
                            {...className(`form-control ${error ? 'is-invalid' : ''} ${this.props.className ? this.props.className : ''}`)}
                        />
                    );
            }
        }

        return (
            <div {...className('form-group')}>
                {label ? <label htmlFor={field}>{label}</label> : null}
                {(Array.isArray(options)) ?

                    <Select id={field} field={field}
                        {...{ placeholder, autoComplete, disabled }}
                        {...className(`form-control ${error ? 'is-invalid' : ''} ${this.props.className ? this.props.className : ''}`)}
                    >
                        {placeholder &&
                            <option value="">{placeholder}</option>
                        }
                        {options.map((op, i) => (
                            <option key={i} value={op.value}>{op.label}</option>
                        ))}
                    </Select>

                    :

                    addInput(type)

                }
                {error && 
                    <div {...className(`invalid-feedback ${errorClassName ? errorClassName : ''}`)}> 
                        {errorText || 'This field is mandatory'}
                    </div> 
                }
            </div>
        )
    }
} 